import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import en from './locales/en/translation.json';
import fr from './locales/fr/translation.json';

const i18n = i18next
  .createInstance({
    lng: 'fr',
    resources: {
      fr: { translation: fr },
      en: { translation: en },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })
  .use(initReactI18next);

i18n.init();

export default i18n;
