import { useState, useEffect } from 'react';

export const usePageYScrollAmount = () => {
  const [yOffset, setYOffset] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const onScroll = () => setYOffset(window.pageYOffset);
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, []);

  return yOffset;
};
