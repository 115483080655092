import { FunctionComponent } from 'react';

import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import { DefaultSiteSeoType } from '../fragments/default-site-seo.fragment';

import i18n from '../i18n';
import Footer from './Footer';
import LocationContext from './locationContext';
import Nav from './Nav';

const DEFAULT_SEO_QUERY = graphql`
  query {
    ...DefaultSiteSeoFragment
  }
`;

const Layout: FunctionComponent<PageProps> = ({ location, children }) => {
  const data = useStaticQuery<DefaultSiteSeoType>(DEFAULT_SEO_QUERY);

  return (
    <I18nextProvider i18n={i18n}>
      <LocationContext.Provider value={location}>
        <Helmet>
          <html lang={i18n.language} />

          <title>{data.site.siteMetadata.title}</title>
          <meta name="description" content={data.site.siteMetadata.description} />

          <meta property="og:type" content="website" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:image:alt" content={data.site.siteMetadata.title} />
          <meta property="og:image" content={data.file.childImageSharp.resize.src} />
        </Helmet>

        <div className="flex min-h-full flex-1 flex-col">
          <Nav />
          <div className="flex-auto">{children}</div>
          <Footer />
        </div>
      </LocationContext.Provider>
    </I18nextProvider>
  );
};

export default Layout;
