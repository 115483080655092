import classNames from 'classnames';
import SocialButtons from './Article/SocialButtons';
import BlogLogo from './BlogLogo';

interface FooterProps {
  className?: string;
}

const Footer = (props: FooterProps) => {
  return (
    <>
      <footer className={classNames('bg-blue-light', props.className)}>
        <div className="mx-auto grid w-full max-w-screen-xl grid-cols-12 items-start gap-y-4 p-6">
          <div className="col-span-12 lg:col-span-4">
            <BlogLogo />
          </div>

          <div className="col-span-12 pt-2 lg:col-span-8">
            <div className="flex flex-col-reverse items-start gap-y-4 lg:flex-row">
              <ul className="flex grow flex-col gap-y-6 text-emphasis">
                <li>
                  <a href="https://gojob.com/la-famille-gojob/" target="_blank" rel="noreferrer">
                    Qui sommes nous ?
                  </a>
                </li>
                <li>
                  <a href="https://gojob.com/gojob-foundation" target="_blank" rel="noreferrer">
                    La Gojob foundation
                  </a>
                </li>
                <li>
                  <a href="https://gojob.com/entreprise/gojob-ai-lab/" target="_blank" rel="noreferrer">
                    Gojob AI Lab
                  </a>
                </li>
                <li>
                  <a href="https://gojob.com/medias/" target="_blank" rel="noreferrer">
                    Espace médias
                  </a>
                </li>
                <li>
                  <a href="https://gojob.com/actualites/" target="_blank" rel="noreferrer">
                    Nos actualités
                  </a>
                </li>
                <li>
                  <a href="https://gojob.com/wearehiring/" target="_blank" rel="noreferrer">
                    Gojob recrute
                  </a>
                </li>
              </ul>
              <div className="w-[72px] border border-yellow lg:hidden"></div>
              <SocialButtons
                className="lg:col-start-0 flex-row lg:col-span-4 lg:justify-end"
                shareOrigin={true}
              />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
