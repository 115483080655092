import classNames from 'classnames';
import { Link } from 'gatsby';
import { FunctionComponent } from 'react';
import Logo from './Logo';

interface BlogLogoProps {
  onClick?: () => void;
  baseColors?: boolean;
  className?: string;
}

const BlogLogo: FunctionComponent<BlogLogoProps> = (props) => {
  const { onClick, baseColors, className } = props;

  return (
    <div className={classNames('lg:px-4 w-fit', className)}>
      <Link to="/" className="flex h-auto w-auto items-center gap-x-2 rounded-md py-2" onClick={onClick}>
        <Logo aria-label="Gojob" className={classNames('h-8 logo', baseColors ? 'fill-white' : 'fill')} />
        <div className=" h-[9px] w-[9px] rounded bg-yellow"></div>
        <div
          className={classNames(
            'logo-subtitle typography-primary-strong',
            baseColors ? 'text-white' : 'text',
          )}
        >
          Blog
        </div>
      </Link>
    </div>
  );
};

export default BlogLogo;
