import { cloneElement, FunctionComponent, ReactElement, useState } from 'react';

import Facebook from '@gojob/style/Icons/Facebook';
import LinkedIn from '@gojob/style/Icons/Linkedin';
import Twitter from '@gojob/style/Icons/Twitter';
import Link from '@gojob/style/Icons/Link';
import classNames from 'classnames';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';

interface SocialButtonsProps {
  shareOrigin?: boolean;
  className?: string;
}
const emojis = ['👍', '🤌', '👏', '💪', '👌', '🤟', '🎉'];

const SocialButtons: FunctionComponent<SocialButtonsProps> = (props) => {
  const { shareOrigin, className } = props;

  const location = useLocation();

  const { t } = useTranslation();
  const [copiedEmoji, setCopiedEmoji] = useState<boolean | string>(false);
  const url = shareOrigin ? location.origin : location.href;

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(url);
    const randomEmojiIndex = Math.floor(Math.random() * emojis.length);
    setCopiedEmoji(emojis[randomEmojiIndex]);
  };

  return (
    <div className={classNames('flex gap-4 items-center', className)}>
      <LinkedinShareButton url={url}>
        <SocialButton Icon={<LinkedIn />} />
      </LinkedinShareButton>
      <FacebookShareButton url={url}>
        <SocialButton Icon={<Facebook />} />
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <SocialButton Icon={<Twitter />} />
      </TwitterShareButton>
      <SocialButton Icon={<Link />} onClick={copyUrlToClipboard} />
      {copiedEmoji && (
        <div className="copied-link flex flex-col items-center gap-2">
          <span className="text-xl">{copiedEmoji}</span>
          <span className="font-semibold text-green">{t('link_copied_to_clipboard')}</span>
        </div>
      )}
    </div>
  );
};

interface SocialButtonProps {
  Icon?: ReactElement<{ className?: string }>;
  onClick?: () => void;
}
const SocialButton: FunctionComponent<SocialButtonProps> = (props) => {
  const { Icon, onClick } = props;
  return (
    <div
      onClick={onClick}
      className="flex h-[48px] w-[48px] shrink-0 cursor-pointer items-center justify-center rounded-full border border-blue"
    >
      {Icon
        ? cloneElement(Icon, {
            className: classNames('w-6 h-6 fill-blue', Icon.props.className),
          })
        : null}
    </div>
  );
};

export default SocialButtons;
