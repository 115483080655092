import { useContext, useState } from 'react';
import Menu from '@gojob/style/Icons/Menu';
import BurgerClose from '@gojob/style/Icons/BurgerClose';
import { useTranslation } from 'react-i18next';
import SecondaryButton from '@gojob/style/Buttons/Secondary';
import PrimaryButton from '@gojob/style/Buttons/Primary';
import classNames from 'classnames';

import BlogLogo from './BlogLogo';
import { Link } from 'gatsby';
import LocationContext from './locationContext';
import { usePageYScrollAmount } from './usePageYScrollAmount';
import NavList from './NavList';

const STICKY_SCROLL_AMOUNT = 80;

const Nav = () => {
  const { t } = useTranslation();

  const location = useContext(LocationContext);

  const [mobileMenu, setMobileMenu] = useState(false);

  const yScrollAmount = usePageYScrollAmount();
  const isHome = location.pathname === '/';
  const homePageStyle = isHome && !mobileMenu;
  const SubscribeButton = homePageStyle ? SecondaryButton : PrimaryButton;
  const MobileMenuIcon = !mobileMenu ? Menu : BurgerClose;

  return (
    <div
      className={classNames(
        'sticky top-0 z-50 lg:static lg:shadow-none px-6 home-nav ',
        mobileMenu ? '!fixed inset-0 !bg-white pb-4 mobile-menu' : '',
        homePageStyle ? 'home-page-style' : '',
        yScrollAmount > STICKY_SCROLL_AMOUNT ? 'scrolled shadow-md' : '',
      )}
    >
      <nav className="relative mx-auto h-full w-full max-w-screen-xl">
        <button
          aria-label={t('toggle-menu') ?? 'Toggle Menu'}
          className="absolute top-[24px] right-0 block cursor-pointer !p-0 lg:hidden"
          onClick={() => setMobileMenu(!mobileMenu)}
        >
          <MobileMenuIcon
            className={classNames(
              'w-6',
              homePageStyle && yScrollAmount <= STICKY_SCROLL_AMOUNT ? 'fill-white' : 'fill-blue',
              mobileMenu ? '!fill-grey' : '',
            )}
          />
        </button>

        <div
          className={classNames(
            'flex flex-col py-1 lg:p-2 gap-y-2 lg:flex-row lg:items-center items-left h-full',
          )}
        >
          <BlogLogo baseColors={homePageStyle} onClick={() => setMobileMenu(false)} />

          <NavList
            onRouteChange={() => setMobileMenu(false)}
            className={classNames(
              'lg:!flex flex-col items-end pt-8 text-2xl lg:flex-row lg:items-center lg:pt-[4px] lg:text-base lg:px-8',
              mobileMenu ? 'flex' : 'hidden',
            )}
          />

          <SubscribeButton
            className={classNames('items-center lg:flex mt-auto lg:mt-0', mobileMenu ? 'flex' : 'hidden')}
            as={Link}
            to="/staytuned"
            onClick={() => setMobileMenu(false)}
          >
            {t('subscribe')}
          </SubscribeButton>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
