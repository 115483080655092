import classNames from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Fragment, FunctionComponent, Suspense, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LocationContext from './locationContext';
import loadable from '@loadable/component';

interface SearchPostsProps {
  publicIndexURL: string;
  publicStoreURL: string;
  onSelection: () => void;
}

const SearchPosts = loadable<SearchPostsProps>(
  async (props) => {
    const { default: SearchPostsComponent } = await import('./SearchPosts');
    const [index, store] = await Promise.all([
      fetch(props.publicIndexURL).then((r) => r.text()),
      fetch(props.publicStoreURL).then((r) => r.json()),
    ]);

    const RenderSearchPosts = () => (
      <SearchPostsComponent onSelection={props.onSelection} index={index} store={store} />
    );
    return RenderSearchPosts;
  },
  { ssr: false },
);

interface LocalSearchQueryResponse {
  localSearchPosts: {
    publicIndexURL: string;
    publicStoreURL: string;
  };
}

export const LOCAL_SEARCH_QUERY = graphql`
  query {
    localSearchPosts {
      publicIndexURL
      publicStoreURL
    }
  }
`;

interface NavListProps {
  onRouteChange: () => void;
  className?: string;
}

const NavList: FunctionComponent<NavListProps> = (props) => {
  const { onRouteChange, className } = props;
  const { localSearchPosts } = useStaticQuery<LocalSearchQueryResponse>(LOCAL_SEARCH_QUERY);

  return (
    <ul className={classNames('w-full flex-col-reverse gap-8', className)}>
      <li>
        <NavItem label="team" to="/team" onRouteChange={onRouteChange} extraPageMatches={['/author/']} />
      </li>
      <li className="grow">
        <NavItem label="events" to="/events" onRouteChange={onRouteChange} />
      </li>

      <li className="w-full lg:w-auto">
        <Suspense fallback={<Fragment />}>
          <SearchPosts
            onSelection={onRouteChange}
            publicIndexURL={localSearchPosts.publicIndexURL}
            publicStoreURL={localSearchPosts.publicStoreURL}
          />
        </Suspense>
      </li>
    </ul>
  );
};
interface NavItemProps {
  label: string;
  to: string;
  onRouteChange: () => void;
  className?: string;
  extraPageMatches?: string[];
}

const NavItem: FunctionComponent<NavItemProps> = (props) => {
  const { label, to, onRouteChange, className } = props;
  const location = useContext(LocationContext);

  const { t } = useTranslation();

  const isCurrentPage =
    location.pathname === `/${label}/` ||
    (props.extraPageMatches && props.extraPageMatches.some((match) => location.pathname.startsWith(match)));

  return (
    <Link
      onClick={onRouteChange}
      to={to}
      className={classNames('nav-item pt-[4px]', isCurrentPage ? 'exclamation-right' : '', className)}
    >
      {t(label)}
    </Link>
  );
};

export default NavList;
